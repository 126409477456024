

const initialUserState = {
    count: 0
};

export default function userState(state = initialUserState, action) {
    if (action.type === "DECREMENTCOUNT") {
        console.log("payloadTHISONE", state);
        return {
            count: state.count - 1,
        }
    }
    if (action.type ==="INCREMENTCOUNT"){
        return {
            count: state.count + 1,
        }
    }
    if (action.type ==="RESETCOUNT"){
        return {
            count: state.count + 1,
        }
    }

    else {
        return state
    }
}