import React from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import Home from './HomeComponent';
import Planner from './PlannerComponent';
import Header from './HeaderComponent';
import Map from './MapComponent';
import Footer from "./FooterComponent";


const MainComponent = () => {
    return (
        <div>
            <Header></Header>
            <Switch>
                <Route path="/home" component={Home}/>
                <Route path="/planner" component={Planner}/>
                <Route path="/map" component={Map}/>
                <Redirect to="/home" />
            </Switch>
            <Footer/>
        </div>
    );
}

export default MainComponent;