import {BrowserRouter, HashRouter} from 'react-router-dom';
import Main from './components/MainComponent'
import './css/styles.scss';
import React from "react";
import {Provider} from 'react-redux';
import store from "./store";



function App() {
    return (
        <Provider store={store}>
            <HashRouter>
                <Main/>
            </HashRouter>
        </Provider>

    );
}

export default App;


/*
{data.map((data, index) =>{
  return(
      <div key={index}>
        <p>{data.bar}</p>
        <p>{data.happyHour}</p>
        <p>{data.postCode}</p>
      </div>
  )
})
}*/
