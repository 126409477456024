
export const data = [
    {
    id: "1",
    bar: "Albert's Schloss",
    offer: "£5 Cocktails, £20 Prosecco Bottle",
    details: "Join Albert Schloss for some Apres Ski. It's just £5 on selected cocktails and £20 for a bottle of Prosecco!",
    days: "Mon, Tue, Wed, Thu, Fri",
    happyHour: "16:00-19:00",
    address: "27 Peter Street",
    src: "./assets/bars/schloss.jpg",
    postCode: "M2 5QR",
        lat: 53.477951,
        long: -2.246670
},
    {
        id: "2",
        bar: "Impossible",
        offer: "Bottomless Brunch",
        days: "Tues, Wed, Thur, Fri, Sat",
        details:"Choose a Burger, Flatbread or salad and enjoy 90 minutes of bottomless drinks - including beer, cocktails wine and Prosecco",
        happyHour: "12:00-17:00",
        address: "28 Peter Street",
        src: "./assets/bars/drink1.jpg",
        postCode: "M2 5QR",
        lat: 53.478031,
        long: -2.247400

    },

    {
        id: "3",
        bar: "Liars Club",
        offer: "2-4-£10, £10 Pizza",
        days: "Sun, Mon, Tue, Wed, Thur, Fri",
        details: "After work happy hours 5-9pm Sunday to Friday, 2-4- £10 cocktails, £2 slices, £10 pizzas and cheap plonk along with £3 Hooch/Hard Seltzer!",
        happyHour: "17:00-21:00",
        address: "19A Back Bridge Street",
        src: "./assets/bars/liars.jpg",
        postCode: "M3 2PB",
        lat: 53.481310,
        long: -2.249630
    },
    {
        id: "4",
        bar: "Dirty Martini",
        offer: "Half price Cocktails & others",
        days: "Mon, Tue, Wed, Thur",
        happyHour: "15:00-20:00",
        address: "1 Peter Street",
        src: "./assets/bars/dm.jpg",
        postCode: "M2 5QR",
        lat: 53.478360,
        long: -2.249540
    },
    {
        id: "5",
        bar: "Slug & Lettuce",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Fri, Sat, Sun",
        happyHour: "11:00-23:00",
        details: "It's 2-4-1 on the same cocktails all day, everyday!",
        address: "Albert Square",
        src: "./assets/bars/sandl.jpg",
        postCode: "M2 5HD",
        lat:53.479640,
        long: -2.245510
    },
    {
        id: "6",
        bar: "Dirty Martini",
        offer: "Half price Cocktails & others",
        days: "Sun",
        happyHour: "12:00-22:00",
        address: "1 Peter Street",
        src: "./assets/bars/dm.jpg",
        postCode: "M2 5QR",
        lat: 53.478360,
        long: -2.249540
    },
    {
        id: "7",
        bar: "Slug & Lettuce",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Fri, Sat, Sun",
        details: "It's 2-4-1 on the same cocktails all day, everyday!",
        happyHour: "11:00-23:00",
        address: "Deansgate",
        src: "./assets/bars/sandl.jpg",
        postCode: "M3 2EN",
        lat:53.478890,
        long: -2.249230
    },
    {
        id: "8",
        bar: "Slug & Lettuce",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Fri, Sat, Sun",
        details: "It's 2-4-1 on the same cocktails all day, everyday!",
        happyHour: "11:00-23:00",
        address: "Spinningfields",
        src: "./assets/bars/sandl.jpg",
        postCode: "M3 3AN",
        lat:53.480930,
        long: -2.253500
    },
    {
        id: "9",
        bar: "Slug & Lettuce",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Fri, Sat, Sun",
        details: "It's 2-4-1 on the same cocktails all day, everyday!",
        happyHour: "11:00-23:00",
        address: "Piccadilly",
        src: "./assets/bars/sandl.jpg",
        postCode: "M1 3HU",
        lat:53.478460,
        long: -2.238600
    },
    {
        id: "10",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Sun",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "10:00-19:00",
        address: "33-35 Oxford St",
        src: "./assets/bars/tb.jpg",
        postCode: "M1 4BH",
        lat:53.475680,
        long: -2.242090
    },
    {
        id: "11",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Sun",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "21:30-23:59",
        address: "33-35 Oxford St",
        src: "./assets/bars/tb.jpg",
        postCode: "M1 4BH",
        lat:53.475680,
        long: -2.242090
    },
    {
        id: "12",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Fri, Sat",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "10:00-19:00",
        address: "33-35 Oxford St",
        src: "./assets/bars/tb.jpg",
        postCode: "M1 4BH",
        lat:53.475680,
        long: -2.242090
    },
    {
        id: "13",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Fri, Sat",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "22:00-23:59",
        address: "33-35 Oxford St",
        src: "./assets/bars/tb.jpg",
        postCode: "M1 4BH",
        lat:53.475680,
        long: -2.242090
    },
    {
        id: "14",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Sun",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "10:00-19:00",
        address: "46 Oldham St",
        src: "./assets/bars/tb.jpg",
        postCode: "M4 1LE",
        lat:53.483356,
        long: -2.234089
    },
    {
        id: "15",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thur, Sun",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "21:30-23:59",
        address: "46 Oldham St",
        src: "./assets/bars/tb.jpg",
        postCode: "M4 1LE",
        lat:53.483356,
        long: -2.234089
    },
    {
        id: "16",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Fri, Sat",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "22:00-23:59",
        address: "46 Oldham St",
        src: "./assets/bars/tb.jpg",
        postCode: "M4 1LE",
        lat:53.483356,
        long: -2.234089
    },
    {
        id: "17",
        bar: "Turtle Bay",
        offer: "2-4-1 Cocktails",
        days: "Fri, Sat",
        details: "It's 2-4-1 on cocktails every single day",
        happyHour: "22:00-23:59",
        address: "46 Oldham St",
        src: "./assets/bars/tb.jpg",
        postCode: "M4 1LE",
        lat:53.483356,
        long: -2.234089
    },
    {
        id: "18",
        bar: "Dive NQ",
        offer: "All drinks £3.50",
        days: "Sun, Mon, Tue, Wed, Thur, Fri",
        details: "The list includes bottled beers, pints of draught, single spirit and mixers, glasses of wine, selected cocktails and Prosecco",
        happyHour: "17:00-22:00",
        address: "Tib St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M4 1SH",
        lat:53.482175,
        long: -2.236979
    },
    {
        id: "19",
        bar: "The Bay Horse Tavern",
        offer: "Bottomless Brunch",
        days: "Sun, Sat",
        details: "One item of food and unlimited selected drinks including Prosecco, selected Beers and selected Cocktails",
        happyHour: "11:00-14:00",
        address: "35-37 Thomas Street",
        src: "./assets/bars/drink1.jpg",
        postCode: "M4 1NA",
        lat:53.484088,
        long: -2.236535
    },
    {
        id: "20",
        bar: "Affleck and Brown",
        offer: "Discounted drinks inc. £20 Prosecco",
        days: "Sun, Sat, Mon, Tue, Wed, Thu, Fri",
        details: "You can grab a pint of Amstel for £3.20, two Sol for £6, a bottle of house wine for £10 or prosecco for £16. You can also get a vodka Red Bull for £4, and the rotating beer of the week is £2.50",
        happyHour: "11:00-14:00",
        address: "4 Hilton St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M4 1NB",
        lat:53.483167,
        long: -2.235417
    },
    {
        id: "21",
        bar: "Black Dog Ballroom",
        offer: "£4.50 Cocktails, £16 Prosecco",
        days: "Sun, Mon, Tue, Wed, Thu, Fri",
        details: "Selected Cocktails £4.50, Selected beers £3.50, a bottle of wine is £12 and Prosecco is £16",
        happyHour: "16:00-20:00",
        address: "52 Church Street",
        src: "./assets/bars/drink1.jpg",
        postCode: "M4 1PW",
        lat:53.482879638671875,
        long: -2.236579418182373
    },
    {
        id: "22",
        bar: "Lost in Tokyo",
        offer: "2-4-£10 Cocktails",
        days: "Sun, Mon, Tue, Wed, Thu, Fri, Sat",
        details: "All cocktails are 2 for £10, all day, everyday!",
        happyHour: "15:00-23:59",
        address: "26A LEVER STREET",
        src: "./assets/bars/drink1.jpg",
        postCode: "M1 1DN",
        lat:53.48271942138672,
        long: -2.2335915565490723
    },
    {
        id: "23",
        bar: "TwentyTwentyTwo",
        offer: "£5 cocktails and £16 Prosecco",
        days: "Wed",
        details: "WHEN YOU’VE GOT CHAMPAGNE TASTE WITH LEMONADE POCKETS. WE’LL MEET YOU IN THE MIDDLE. £15 BOTTLES OF PROSECCO AND £5 COCKTAILS ALL NIGHT!",
        happyHour: "16:00-23:59",
        address: "Little Lever St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M1 1EZ",
        lat:53.481748,
        long: -2.234641
    },
    {
        id: "24",
        bar: "El Capo",
        offer: "50% off Tequila",
        days: "Thu",
        details: "It's Tequila Thursdays. 1 tequila, 2 tequila, 3 tequila.....more?",
        happyHour: "16:00-23:59",
        address: "12 Tariff St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M1 2FF",
        lat:53.481483459472656,
        long: -2.2316391468048096
    },
    {
        id: "25",
        bar: "Liquor & Burn",
        offer: "Half price food and 50% off Margaritas",
        days: "Mon, Tue, Wed, Thu",
        details: "£4.25 Margaritas and half price food - plenty to get your teeth into",
        happyHour: "16:00-18:00",
        address: "12 Tariff St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M1 2FF",
        lat:53.481483459472656,
        long: -2.2316391468048096
    },
    {
        id: "26",
        bar: "Trof",
        offer: "£3.50 Beer and £5 selected Cocktails",
        days: "Mon, Tue, Wed, Thu, Fri",
        details: "£3.50 Lager and Spirit & Mixer, £5 cocktails, £12.50 house wine and £20 Prosecco",
        happyHour: "16:00-21:00",
        address: "8 Thomas St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M4 1EU",
        lat:53.481419,
        long: -2.232574
    },
    {
        id: "27",
        bar: "Revolution De Cuba",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thu, Fri, Sun",
        details: "£3.50 Lager, 2-4-1 coctails and £20 Prosecco",
        happyHour: "15:00-19:00",
        address: "11 Peter St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M2 5QR",
        lat:53.4783384,
        long: -2.2490039
    },
    {
        id: "28",
        bar: "Manahatta",
        offer: "2-4-1 Cocktails",
        days: "Mon, Tue, Wed, Thu, Sun",
        details: "Get 2-4-1 on selected Cocktails",
        happyHour: "12:00-23:59",
        address: "188-192 Deansgate",
        src: "./assets/bars/mana.jpg",
        postCode: "M3 3ND",
        lat:53.479373931884766,
        long: -2.248936891555786
    },
    {
        id: "29",
        bar: "Manahatta",
        offer: "2-4-1 Cocktails",
        days: "Fri",
        details: "Get 2-4-1 on selected Cocktails",
        happyHour: "12:00-22:00",
        address: "188-192 Deansgate",
        src: "./assets/bars/mana.jpg",
        postCode: "M3 3ND",
        lat:53.479373931884766,
        long: -2.248936891555786
    },
    {
        id: "30",
        bar: "Flight Club",
        offer: "£20 Prosecco, £40 Champagne",
        days: "Mon, Tue, Wed, Thur,Fri, Sat, Sun",
        details: "Join us for afternoon fizz with Prosecco £20 a bottle and Champagne just £40 per bottle",
        happyHour: "15:00-19:00",
        address: "98 King St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M2 4WU",
        lat:53.48091506958008,
        long: -2.2432775497436523
    },
    {
        id: "31",
        bar: "The Directors Box",
        offer: "2-4-1 cocktails",
        days: "Mon, Tue, Wed, Thur,Fri, Sat, Sun",
        details: "2-4-1 on the same cocktail, all day everyday. With live sport available, what more could you want?",
        happyHour: "10:00-23:00",
        address: "37 Booth St",
        src: "./assets/bars/drink1.jpg",
        postCode: "M2 4AA",
        lat:53.479801177978516,
        long: -2.2426671981811523
    },
    {
        id: "32",
    bar: "Hard Rock Cafe",
    offer: "£5.25 Cocktails",
    days: "Mon, Tue, Wed, Thur,Sun",
    details: "£2 off draught beer, £3.50 on selected wines and £5.25 on selected cocktails",
    happyHour: "16:00-19:00",
    address: "The Printworks",
    src: "./assets/bars/HR.jpg",
    postCode: "M4 2BS",
    lat:53.48518371582031,
    long: -2.2407755851745605
},
    {
        id: "33",
        bar: "Hard Rock Cafe",
        offer: "£5.25 Cocktails",
        days: "Fri, Sat",
        details: "£2 off draught beer, £3.50 on selected wines and £5.25 on selected cocktails",
        happyHour: "21:00-23:59",
        address: "The Printworks",
        src: "./assets/bars/HR.jpg",
        postCode: "M4 2BS",
        lat:53.48518371582031,
        long: -2.2407755851745605
    }

];