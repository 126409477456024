
export const suggested = [
    {
    day: "Monday",
    bar1: "Albert Schloss",
    bar2: "Liars Club",
    bar3: "Dirty Martini",
    bar4: "Slug & Lettuce",
    bar5: "Turtle Bay",
    bar6: "Dive NQ",
    bar1Offer: "£5 Cocktails",
    bar2Offer: "2-4-£10, £10 Pizza",
    bar3Offer: "2-4-1 Cocktails",
    bar4Offer: "2-4-1 Cocktails",
    bar5Offer: "2-4-1 Cocktails",
    bar6Offer: "All drinks £3.50",
    bar1Hours: "16:00-19:00",
    bar2Hours: "17:00-21:00",
    bar3Hours: "15:00-20:00",
    bar4Hours: "11:00-23:00",
    bar5Hours: "10:00-19:00",
    bar6Hours: "17:00-22:00",
    bar1Address: "27 Peter Street",
    bar2Address: "19A Back Bridge Street",
    bar3Address: "1 Peter Street",
    bar4Address: "Albert Square",
    bar5Address: "33 Oxford St",
    bar6Address: "Tib St",
},
    {
        day: "Tuesday",
        bar1: "Albert Schloss",
        bar2: "Liars Club",
        bar3: "Dirty Martini",
        bar4: "Slug & Lettuce",
        bar5: "Turtle Bay",
        bar6: "Dive NQ",
        bar1Offer: "£5 Cocktails",
        bar2Offer: "2-4-£10, £10 Pizza",
        bar3Offer: "2-4-1 Cocktails",
        bar4Offer: "2-4-1 Cocktails",
        bar5Offer: "2-4-1 Cocktails",
        bar6Offer: "All drinks £3.50",
        bar1Hours: "16:00-19:00",
        bar2Hours: "17:00-21:00",
        bar3Hours: "15:00-20:00",
        bar4Hours: "11:00-23:00",
        bar5Hours: "10:00-19:00",
        bar6Hours: "17:00-22:00",
        bar1Address: "27 Peter Street",
        bar2Address: "19A Back Bridge Street",
        bar3Address: "1 Peter Street",
        bar4Address: "Albert Square",
        bar5Address: "33 Oxford St",
        bar6Address: "Tib St",

    },

    {
        day: "Wednesday",
        bar1: "Albert Schloss",
        bar2: "Liars Club",
        bar3: "Dirty Martini",
        bar4: "Slug & Lettuce",
        bar5: "Turtle Bay",
        bar6: "Dive NQ",
        bar1Offer: "£5 Cocktails",
        bar2Offer: "2-4-£10, £10 Pizza",
        bar3Offer: "2-4-1 Cocktails",
        bar4Offer: "2-4-1 Cocktails",
        bar5Offer: "2-4-1 Cocktails",
        bar6Offer: "All drinks £3.50",
        bar1Hours: "16:00-19:00",
        bar2Hours: "17:00-21:00",
        bar3Hours: "15:00-20:00",
        bar4Hours: "11:00-23:00",
        bar5Hours: "10:00-19:00",
        bar6Hours: "17:00-22:00",
        bar1Address: "27 Peter Street",
        bar2Address: "19A Back Bridge Street",
        bar3Address: "1 Peter Street",
        bar4Address: "Albert Square",
        bar5Address: "33 Oxford St",
        bar6Address: "Tib St",
    },
    {
        day: "Thursday",
        bar1: "Albert Schloss",
        bar2: "Liars Club",
        bar3: "Dirty Martini",
        bar4: "Slug & Lettuce",
        bar5: "Turtle Bay",
        bar6: "Dive NQ",
        bar1Offer: "£5 Cocktails",
        bar2Offer: "2-4-£10, £10 Pizza",
        bar3Offer: "2-4-1 Cocktails",
        bar4Offer: "2-4-1 Cocktails",
        bar5Offer: "2-4-1 Cocktails",
        bar6Offer: "All drinks £3.50",
        bar1Hours: "16:00-19:00",
        bar2Hours: "17:00-21:00",
        bar3Hours: "15:00-20:00",
        bar4Hours: "11:00-23:00",
        bar5Hours: "10:00-19:00",
        bar6Hours: "17:00-22:00",
        bar1Address: "27 Peter Street",
        bar2Address: "19A Back Bridge Street",
        bar3Address: "1 Peter Street",
        bar4Address: "Albert Square",
        bar5Address: "33 Oxford St",
        bar6Address: "Tib St",
    },
    {
        day: "Friday",
        bar1: "Albert Schloss",
        bar2: "Liars Club",
        bar3: "Black og Ballroom",
        bar4: "Slug & Lettuce",
        bar5: "Turtle Bay",
        bar6: "Dive NQ",
        bar1Offer: "£5 Cocktails",
        bar2Offer: "2-4-£10, £10 Pizza",
        bar3Offer: "£4.50 Cocktails",
        bar4Offer: "2-4-1 Cocktails",
        bar5Offer: "2-4-1 Cocktails",
        bar6Offer: "All drinks £3.50",
        bar1Hours: "16:00-19:00",
        bar2Hours: "17:00-21:00",
        bar3Hours: "16:00-20:00",
        bar4Hours: "11:00-23:00",
        bar5Hours: "10:00-19:00",
        bar6Hours: "17:00-22:00",
        bar1Address: "27 Peter Street",
        bar2Address: "19A Back Bridge Street",
        bar3Address: "52 Church St",
        bar4Address: "Albert Square",
        bar5Address: "33 Oxford St",
        bar6Address: "Tib St",
    },
    {
        day: "Saturday",
        bar1: "Lost in Tokyo",
        bar2: "Flight Club",
        bar3: "The Directors Box",
        bar4: "Slug & Lettuce",
        bar5: "Impossible",
        bar6: "Turtle Bay",
        bar1Offer: "2-4-£10 Cocktails",
        bar2Offer: "£20 Prosecco",
        bar3Offer: "2-4-1 Cocktails",
        bar4Offer: "2-4-1 Cocktails",
        bar5Offer: "Bottomless Brunch",
        bar6Offer: "2-4-1 Cocktails",
        bar1Hours: "15:00-23:59",
        bar2Hours: "15:00-19:00",
        bar3Hours: "10:00-23:00",
        bar4Hours: "11:00-23:00",
        bar5Hours: "12:00-17:00",
        bar6Hours: "10:00-19:00",
        bar1Address: "26A Lever St",
        bar2Address: "98 King St",
        barAddress: "37 Booth St",
        bar4Address: "Spinningfields",
        bar5Address: "28 Peter St",
        bar6Address: "33 Oxford St",
    },
    {
        day: "Sunday",
        bar1: "Bay Horse Tavern",
        bar2: "Liars Club",
        bar3: "Black Dog Ballroom",
        bar4: "Slug & Lettuce",
        bar5: "Turtle Bay",
        bar6: "Dive NQ",
        bar1Offer: "Bottomless Brunch",
        bar2Offer: "2-4-£10, £10 Pizza",
        bar3Offer: "£4.50 Cocktails",
        bar4Offer: "2-4-1 Cocktails",
        bar5Offer: "2-4-1 Cocktails",
        bar6Offer: "All drinks £3.50",
        bar1Hours: "11:00-14:00",
        bar2Hours: "17:00-21:00",
        bar3Hours: "16:00-20:00",
        bar4Hours: "11:00-23:00",
        bar5Hours: "10:00-19:00",
        bar6Hours: "17:00-22:00",
        bar1Address: "35 Thomas St",
        bar2Address: "19A Back Bridge Street",
        bar3Address: "52 Church St",
        bar4Address: "Albert Square",
        bar5Address: "33 Oxford St",
        bar6Address: "Tib St",
    }
];