const initialUserState = {
    feature: undefined
};

export default function userState(state = initialUserState, action) {
    console.log("featred", action);
    if (action.type === 'feature') {
        return {
        feature: action.payload
        }
    } else {
        return {
            state
        }
    }
}
