import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {data} from "../data/TableData";
import {suggested} from "../data/BarDays";
import DatePicker from "react-datepicker";
import MapComponent from "./MapComponent";
import {
    Container,
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardText,
    ModalHeader,
    ModalBody,
    Button,
    Modal
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import en from 'date-fns/locale/en-GB/index';
import {format} from "date-fns";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {addToPlanner, addCount, increment, decrement} from "../store/actions";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



registerLocale('en', en)


const AllBars = (props) => {
    const dispatch = useDispatch();
    const planner = useSelector((state) => state.planner.arr);
    const counter = useSelector((state) => state.countReducer.count);
    const [pickList, updatePicklist] = useState(planner);
    const [isModalOpen, toggleModal] = useState(false);
    const [modalData, setModalData] = useState('Please Select a Bar');
    const handleAddClick = (data) => {
        toggleModal(true);
        setModalData(data);
    };
    const handleClose = () => {
        toggleModal(false)
    };
    const chosenDate = props.props;
    const chosenDateString = JSON.stringify(chosenDate);


    const gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];

    const d = new Date(chosenDate);
    const dayName = gsDayNames[d.getDay()];


    useEffect(() => {
        updatePicklist(planner);
    }, [planner], [counter]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function notify(){
        toast.success("Added to your list!");
    }

    function addToList(modalData) {
        if (counter > 5) {
            alert("Whoops! Too many places. Let's start again....");
            window.location.reload();
        } else {
            dispatch(addToPlanner(modalData));
            dispatch(addCount("INCREMENTCOUNT"));
            notify();

        }
    }

    function removeFromList(bars) {

        const list = planner;
        const values = list.indexOf(bars);
        list.splice(values, 1);
        updatePicklist(list);
        dispatch(addToPlanner(planner));
        dispatch(addCount("DECREMENTCOUNT"));

    }


    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(pickList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updatePicklist(items);
    }

    const weekday = new Array(7);
    weekday[0] = "Sun";
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";


    function GoogleMapComp() {
        const [selectedBar, setSelectedBar] = useState(null);
        return (
            <GoogleMap
                defaultZoom={15}
                defaultCenter={{lat: 53.480759, lng: -2.242631}}
            >
                {pickList.map(bars => (
                    <Marker key={bars.id} position={{lat: bars.lat, lng: bars.long}} onClick={() => {
                        setSelectedBar(bars)
                    }} animation={window.google.maps.Animation.DROP}/>
                ))}

                {selectedBar && (
                    <InfoWindow
                        position={{
                            lat: selectedBar.lat,
                            lng: selectedBar.long
                        }}
                        onCloseClick={() => {
                            setSelectedBar(null);
                        }}
                    >
                        <div className="text-center">
                            <p>{selectedBar.bar}</p>
                            <p>{selectedBar.offer}</p>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        );
    }

    const WrappedMap = withScriptjs(withGoogleMap(GoogleMapComp));

    const PlannerMap = () => {
        return (
            <>
                <div className="d-none d-md-block">
                    <div className="container" style={{width: "40vw", height: "50vh"}}>
                        <WrappedMap
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQgDUJ8pI2CTfxIaQGAzu6R9DBmuGp8JE&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: "100%"}}/>}
                            containerElement={<div style={{height: "100%"}}/>}
                            mapElement={<div style={{height: "100%"}}/>}
                        />
                    </div>
                </div>
                <div className="d-block d-md-none">
                    <div style={{width: "100vw", height: "50vh"}}>
                        <WrappedMap
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQgDUJ8pI2CTfxIaQGAzu6R9DBmuGp8JE&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: "100%"}}/>}
                            containerElement={<div style={{height: "100%"}}/>}
                            mapElement={<div style={{height: "100%"}}/>}
                        />
                    </div>
                </div>


            </>


        );
    }


    return (
        <div>
            <Container>
                <div className="d-none d-md-block">
                    <Row className="flexbox-container">
                        <Col>
                            <h5>You can choose up to 6 bars from the tabs, below...</h5>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="pickList">
                                    {(provided) => (
                                        <ol {...provided.droppableProps} ref={provided.innerRef}>
                                            {pickList.map((bars, index) => {
                                                const stringVersion = JSON.stringify(bars);
                                                const ignoreArrayInitializer = stringVersion.substring(0, 15);
                                                const sameDay = JSON.stringify(chosenDate);
                                                const substrDate = sameDay.substring(0, 11);
                                                const date = new Date(substrDate);
                                                const dayJustPicked = date.toLocaleString('en-us', {weekday: 'short'});
                                                const happyDays = bars.days;
                                                const counterValue = JSON.stringify(counter);
                                                const barsalreadyadded = JSON.stringify(pickList);


                                                if ((ignoreArrayInitializer.includes("@")) || (ignoreArrayInitializer.includes("INCREMENTCOUNT")) || (ignoreArrayInitializer.includes("DECREMENTCOUNT")) || (stringVersion.includes("RESETCOUNT"))) {
                                                    return (
                                                        <div></div>
                                                    )
                                                } else if (!happyDays.includes(dayJustPicked)) {
                                                    return (
                                                        <div>
                                                            <li className="plannerCard red">
                                                                <Row>
                                                                    <Col><strong
                                                                        style={{color: "white"}}>{bars.bar}:</strong> {bars.offer}<br/> Sorry,
                                                                        this offer isn't available on this
                                                                        day<br/></Col>
                                                                    <Col md="auto"><Button
                                                                        className="btn-warning remove-btn"
                                                                        onClick={() => removeFromList(bars)}>Remove</Button></Col>
                                                                </Row>
                                                            </li>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <Draggable key={bars.id} draggableId={bars.id}
                                                                   index={index}>
                                                            {(provided) => (
                                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                    className="plannerCard bg-gradient">
                                                                    <Row>
                                                                        <Col><strong>{bars.bar}:</strong> {bars.offer} {bars.happyHour}<br/> {bars.address}, {bars.postCode}<br/></Col>
                                                                        <Col md="auto"><Button
                                                                            className="btn-warning remove-btn"
                                                                            onClick={() => removeFromList(bars)}>Remove</Button></Col>
                                                                    </Row>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            })}
                                            {provided.placeholder}
                                        </ol>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Col>
                        <Col>
                            <PlannerMap/>
                        </Col>
                    </Row>
                </div>
            </Container>

            <div className="d-block d-md-none">
                <Row>
                    <Col>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="pickList">
                                {(provided) => (
                                    <ol {...provided.droppableProps} ref={provided.innerRef}>
                                        {pickList.map((bars, index) => {
                                            const stringVersion = JSON.stringify(bars);
                                            const ignoreArrayInitializer = stringVersion.substring(0, 15);
                                            const sameDay = JSON.stringify(chosenDate);
                                            const substrDate = sameDay.substring(0, 11);
                                            const date = new Date(substrDate);
                                            const dayJustPicked = date.toLocaleString('en-us', {weekday: 'short'});
                                            const happyDays = bars.days;
                                            const counterValue = JSON.stringify(counter);
                                            const barsalreadyadded = JSON.stringify(pickList);
                                            if ((ignoreArrayInitializer.includes("@")) || (ignoreArrayInitializer.includes("INCREMENTCOUNT")) || (ignoreArrayInitializer.includes("DECREMENTCOUNT")) || (stringVersion.includes("RESETCOUNT"))) {
                                                return (
                                                    <div></div>
                                                )
                                            } else if (!happyDays.includes(dayJustPicked)) {
                                                return (
                                                    <div>
                                                        <li className="plannerCard red">
                                                            <Row>
                                                                <Col><strong
                                                                    style={{color: "white"}}>{bars.bar}:</strong> {bars.offer}<br/> Sorry,
                                                                    this offer isn't available on this
                                                                    day<br/></Col>
                                                                <Col md="auto"><Button
                                                                    className="btn-warning remove-btn"
                                                                    onClick={() => removeFromList(bars)}>Remove</Button></Col>
                                                            </Row>
                                                        </li>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <Draggable key={bars.id} draggableId={bars.id}
                                                               index={index}>
                                                        {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                className="plannerCard col-12 text-center font-sml">
                                                                <Row className="align-items-center">
                                                                    <Col><strong>{bars.bar}:</strong></Col>
                                                                    <Col> {bars.offer}<br/> {bars.happyHour}</Col>
                                                                    <Col><Button
                                                                        className="btn-warning remove-btn"
                                                                        onClick={() => removeFromList(bars)}>Remove</Button></Col>
                                                                </Row>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        })}
                                        {provided.placeholder}
                                    </ol>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                    <Col>
                        <PlannerMap/>
                    </Col>
                </Row>

            </div>


            <div className="head-text">
                <div className="head-image">
                    <img className="col-12 rip" src="assets/images/1111.png"
                         style={{display: 'flex', alignItems: 'center'}}></img>
                </div>
                <div className="text-on-image">
                    <h1 style={{display: "flex", flexWrap: "wrap", justifyContent: "center", fontStyle: "bold"}}>
                        <strong>What's on {dayName}'s</strong></h1>
                </div>
            </div>
            <Container>
                 <div className="d-none d-md-block">
                    <div className="row">

                            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                                {data.map((data, index) => {
                                    const sameDay = JSON.stringify(props);
                                    const substrDate = sameDay.substring(10, 20);
                                    const date = new Date(substrDate);
                                    const dayJustPicked = date.toLocaleString('en-us', {weekday: 'short'});
                                    const happyDays = data.days;
                                    if (happyDays.includes(dayJustPicked)) {
                                        return (

                                                <div key={data.id}>
                                                    <Card className="text-center shadow p-3 bg-white rounded bg-gradient uniform-card-planner"
                                                          onClick={() => handleAddClick(data)}>
                                                        <CardBody>
                                                            <CardTitle style={{
                                                                fontWeight: 'bold'
                                                            }} id="name">{data.bar}:</CardTitle>
                                                            <CardText id="days" className="font-sml">{data.days}</CardText>
                                                            <CardText>{data.address}</CardText>
                                                            <CardText>{data.postCode}</CardText>
                                                            <Button className="btn-warning card-btn"
                                                                    onClick={() => addToList(data)}>Add Bar</Button>
                                                        </CardBody>
                                                    </Card>
                                                </div>

                                        );
                                    } else {
                                        return (
                                            <div></div>
                                        );
                                    }
                                })
                                }
                            </div>
                    </div>
                </div>
            </Container>
            <div className="d-block d-md-none">
                {suggested.map(data => {
                    if (dayName === data.day) {
                        return (
                            <Card
                                className=" text-center shadow p-3 mb-5 bg-gradient red rounded col-10 offset-1" >
                                <CardBody>
                                    <CardTitle id="name" className="yel-font"><h3><strong>The {dayName} Hot
                                        List</strong></h3>
                                    </CardTitle><br/>
                                    <CardText
                                        id="days"
                                        className="wht-font"><strong>{data.bar1}</strong>,<br/> {data.bar1Offer}, {data.bar1Hours}
                                    </CardText>
                                    <CardText
                                        id="days"
                                        className="wht-font"><strong>{data.bar2}</strong>,<br/> {data.bar2Offer}, {data.bar2Hours}
                                    </CardText>
                                    <CardText
                                        id="days"
                                        className="wht-font"><strong>{data.bar3}</strong>,<br/> {data.bar3Offer}, {data.bar3Hours}
                                    </CardText>
                                    <CardText
                                        id="days"
                                        className="wht-font"><strong>{data.bar4}</strong>,<br/> {data.bar4Offer}, {data.bar4Hours}
                                    </CardText>
                                    <CardText
                                        id="days"
                                        className="wht-font"><strong>{data.bar5}</strong>,<br/> {data.bar5Offer}, {data.bar5Hours}
                                    </CardText>
                                    <CardText
                                        id="days"
                                        className="wht-font"><strong>{data.bar6}</strong>,<br/> {data.bar6Offer}, {data.bar6Hours}
                                    </CardText>
                                </CardBody>
                            </Card>

                        );
                    }
                })}
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {data.map((data, index) => {
                        const sameDay = JSON.stringify(props);
                        const substrDate = sameDay.substring(10, 20);
                        const date = new Date(substrDate);
                        const dayJustPicked = date.toLocaleString('en-us', {weekday: 'short'});
                        const happyDays = data.days;
                        if (happyDays.includes(dayJustPicked)) {
                            return (
                                <>
                                    <div key={data.id}
                                         style={index % 2 ? {background: "#fdffe0"} : {background: "white"}}>
                                        <Card className="text-center shadow mb-3 p-3 bg-gradient bg-dark rounded uniform-card-planner-sml"
                                              onClick={() => handleAddClick(data)}>
                                            <CardBody>
                                                <CardTitle style={{
                                                    fontWeight: 'bold'
                                                }} id="name" className="yel-font">{data.bar}:</CardTitle>
                                                <CardText className="yel-font" id="days">{data.offer}</CardText>
                                                <CardText className="wht-font" id="days">{data.days}</CardText>
                                                <CardText className="wht-font">{data.address}</CardText>
                                                <CardText className="wht-font">{data.postCode}</CardText>
                                                <Button className="btn-warning card-btn"
                                                        onClick={() => addToList(data)}>Add Bar</Button>
                                            </CardBody>
                                        </Card>

                                    </div>
                                </>
                            );
                        } else {
                            return (
                                <div></div>
                            );
                        }
                    })
                    }
                </div>
            </div>
        </div>

    );
}

const Planner = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const localDate = startDate.toLocaleDateString('en-gb', {
        weekday: 'long',
        year: "numeric",
        month: "2-digit",
        day: "numeric",
    });
    const planner = useSelector((state) => state.planner.arr);

    useEffect(() => {

    }, [planner]);

    return (
        <div>
            <div className="d-none d-md-block">
                <Container className="mt-3" id="planner-top" name="planner-top">
                    <Row className="flexbox-container">
                        <Col>
                            <h3>Choose a date...</h3>
                        </Col>
                        <Col>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} locale="en-gb"
                                        style={{width: "900px", justifyContent: "center"}} dateFormat='dd/MM/yyyy'/>
                        </Col>
                        <Col></Col>
                        <Col><h3 style={{justifyContent: "end"}}>{localDate}</h3></Col>
                    </Row>
                </Container>
            </div>
            <div className="d-block d-md-none">
                <Container className="mt-3">
                    <Row className="flexbox-container">
                        <Col>
                            <h3>Choose a date...</h3>
                        </Col>
                        <Col>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} locale="en-gb"
                                        style={{width: "900px", justifyContent: "center"}} dateFormat='dd/MM/yyyy'/>
                        </Col>
                    </Row>
                    <Row className="text-center mt-3">
                        <p>Select up to 6 bars from the list below:</p>
                    </Row>
                </Container>
            </div>
            <Col>
                <div></div>
            </Col>
            <Col>
                <AllBars props={startDate}/>
                <ToastContainer
                    position="top-right"
                    autoClose={1500}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover={false}
                />
            </Col>
            <Col>
            </Col>
        </div>
    );
}

export default Planner;
