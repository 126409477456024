import React, {Component} from 'react';
import {Navbar, Nav, NavbarToggler, Collapse, NavItem, Jumbotron, Button, Col, Container} from "reactstrap";
import {NavLink, Link} from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    closeNavbar() {
        this.setState({
            isNavOpen: false
        });
    }

    refreshPage() {
        window.location.reload();
    }

    render() {
        return (
            <>
                <div className="darkheader yel-border">
                <div>
                <div className="d-block d-lg-none navbar-expand-lg" style={{ alignItems: 'center'}}>
                    <div className="row">

                    <Navbar dark expand="lg" className="toggleOffset yel-border">
                        <div className="container">
                            <NavbarToggler onClick={this.toggleNav}/>
                            <Collapse isOpen={this.state.isNavOpen} navbar>
                                <Nav navbar>
                                    <NavItem>
                                        <NavLink className="nav-link" to="/home" onClick={this.closeNavbar}>
                                            <link/>
                                            Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link" to="/map" onClick={this.closeNavbar}>
                                            Map
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                    <NavLink className="nav-link" to="/planner" onClick={this.closeNavbar}>
                                        Planner
                                    </NavLink>
                                </NavItem>
                                </Nav>
                            </Collapse>
                        </div>
                    </Navbar>
                    </div>
                </div>
                </div>
                    <div className="col-7 navFloat container">
                    <div className="d-none d-lg-block darkHeader">
                        <Navbar dark expand="md">
                            <div>
                                <NavbarToggler onClick={this.toggleNav}/>
                                <Collapse isOpen={this.state.isNavOpen} navbar>
                                    <Nav navbar className=""
                                         style={{width: "100%"}}>
                                        <NavItem>
                                            <NavLink className="nav-link" to="/home">
                                                Home
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="nav-link" to="/map">
                                                Map
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="nav-link" to="/planner">
                                                Planner
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </div>
                        </Navbar>
                    </div>
                    </div>
                    <div className="darkHeader">
                        <div className="container">
                            {/*                <h1 className="headerFont1 col-12 blink1">Hello</h1><br/>
                <h1 className="headerFont2 col-12 blink2">Manchester</h1><br/>
                <h3 className="headerFont3 col-12 blink3">It's</h3><br/>
                <h1 className="headerFont4 col-12 blink4">HAPPY : HOUR</h1>*/}
                            {/*            <div className="videoHeader">
                <BannerComponent/>
            </div>*/}
                            <img className="col-12" src="assets/images/headerFinal.jpg"
                                 style={{display: 'flex', alignItems: 'center'}}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Header;