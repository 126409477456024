
const initialUserState = {
    arr: ["@@initialStringinitializer"]
};

export default function userState(state = initialUserState, action) {

    if (action.type === 'planner') {

        return {
            ...state,
        arr: [...state.arr, action.payload],
    }
    } else {
        return state
    }
}

