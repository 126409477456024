import React from 'react';
import {NavLink, Link} from "react-router-dom";
import {Row} from 'reactstrap';

const Footer = () => {
    return (
        <div className="bg-grey bg-gradient">
            <div className="container pt-3 mt-3 offset-1 ">
                <div className="row">
                    <div className="col-5 col-sm-4">
                        <h5 className="font-nob-p">Our Address:</h5>
                        <div className="font-nob-p">
                            Manchester Road, Manchester<br/>
                            M1 M62<br/>

                            <i class="fa fa-phone fa-lg"></i> Tel: (0161) 01610161<br/>

                            <i class="fa fa-envelope fa-lg"></i> Email: <a
                            href="mailto:example@emailAddress">matttyldesle@gmail.com</a>
                        </div>

                    </div>
                    <div class="col-2 offset-1 col-sm-2">
                        <h5 className="font-nob-p">Links:</h5>
                        <ul className="list-unstyled">
                            <li><NavLink className="leaveAlone" to="/home">Home</NavLink></li>
                            <li><NavLink className="leaveAlone" to="/map">Map</NavLink></li>
                            <li><NavLink className="leaveAlone" to="/planner">Planner</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-5">
                         <img className="d-block d-none d-lg-block offset-5" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: "200px", height:"150px"}} src="assets/images/logo.png" alt="logo"/>
                    </div>
                    <p>Copyright © Matt Tyldesley</p>
                    <Row>
                    <p className="font-sml"><strong>Offers may vary and often change at short notice. Please double check with the bar before you travel</strong></p>
                    </Row>
                    </div>
            </div>
        </div>

     );
    }



export default Footer;