export const addToPlanner = payload => (
        {
            type: 'planner',
            payload: payload
});

export const addCount = payload => (
    {
        type: payload,
        payload: payload
    });

export const addFeature = feature => (
    {
        type: 'feature',
        payload: feature
    });

export const addDay = day => (
    {
        type: 'day',
        payload: day
    });

export const addTime = time => (
    {
        type: 'time',
        payload: time
    });


