const initialUserState = {
    time: undefined
};

export default function userState(state = initialUserState, action) {
    console.log("TIMEREDUCER", action);
    if (action.type === 'time') {
        return {
            time: action.payload
        }
    } else {
        return {
            state
        }
    }
}
