import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Row, Toast, ToastBody, ToastHeader
} from "reactstrap";
import {useHistory} from 'react-router-dom';
import {data} from '../data/TableData';
import {connect, useDispatch, useSelector} from "react-redux";
import {addCount, addToPlanner} from "../store/actions";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {
    const [isModalOpen, toggleModal] = useState(false);
    const [modalData, setModalData] = useState('Please Select a Bar');
    const locale = 'en';
    const [today, setDate] = React.useState(new Date());
    const [planner, setPlanner] = useState([]);
    const barPlanner = useSelector(state => state.planner);
    const counter = useSelector((state) => state.countReducer.count);
    const dispatch = useDispatch();
    const [offsetY, setOffsetY] = useState();
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    function notify() {
        toast.success("Added to your Planner");
    }

    const handleAddClick = (data) => {
        toggleModal(true);
        setModalData(data);
    };

    const handleAdd = (modalData) => {
        notify();
        addToList(modalData);
        toggleModal(false);
    };


    const history = useHistory();

    const handlePlan = () => {
        history.push("/planner");
    };

    const handleMap = () => {
        history.push("/map");
    };

    const handleClose = () => {
        toggleModal(false)
    };

    const time = today.toLocaleTimeString(locale, {hour: 'numeric', hour12: false});

    const d = new Date();
    const weekday = new Array(7);
    weekday[0] = "Sun";
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";

    const dayOfWeek = weekday[d.getDay()];

    function addToList(modalData) {
        if (counter > 5) {
            alert("Whoops! Too many bars. Let's start again....");
            window.location.reload();
        } else {
            dispatch(addToPlanner(modalData));
            dispatch(addCount("INCREMENTCOUNT"));

        }
    }


    return (
        <>
            <div className="d-none d-md-block">
                <div className="parallax-bg" style={{transform: `translateY(-${offsetY * 0.11}px)`}}
                />
                <div className="parallax-data">
                    <div className="text-center container ">
                        <div>
                            <h1><span><strong> Welcome to The City Uncovered.</strong></span><br/></h1><br/>
                        </div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>

                            <h6><span>A collection of all Manchester's Happy
                            Hours, meals, deals and all round bargains.</span></h6>

                        </div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                            <Card className="p-3 shadow action-card">
                                <CardBody>
                                    <CardTitle style={{
                                        fontWeight: 'bold'
                                    }}>Planning ahead?</CardTitle>
                                    <CardText>Whats on the next time you're in town.</CardText>
                                    <Button className="btn-warning mb-3 shadow card-btn"
                                            style={{justifyContent: "center"}} onClick={handlePlan}>Planner</Button>
                                </CardBody>
                            </Card>
                            <Card className="p-3 shadow action-card">
                                <CardBody>
                                    <CardTitle style={{
                                        fontWeight: 'bold'
                                    }}>Need direction?</CardTitle>
                                    <CardText>Lost? Find your next Hour of Happy</CardText>
                                    <Button className="btn-warning mb-3 shadow card-btn" onClick={handleMap}>The
                                        Map</Button>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="d-block d-md-none hiveImg">
                    <div className="text-center container parallax-data-sm">
                        <div>
                            <h1><span><strong> Welcome to The City Uncovered.</strong></span><br/></h1><br/>
                        </div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>

                            <h6><span>A collection of all Manchester's Happy
                            Hours, meals, deals and all round bargains.</span></h6>
                        </div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                            <Card className="p-3 shadow action-card">
                                <CardBody>
                                    <CardTitle style={{
                                        fontWeight: 'bold'
                                    }}>Planning ahead?</CardTitle>
                                    <CardText>Whats on the next time you're in town.</CardText>
                                    <Button className="btn-warning mb-3 shadow card-btn"
                                            style={{justifyContent: "center"}} onClick={handlePlan}>Planner</Button>
                                </CardBody>
                            </Card>
                            <Card className="p-3 shadow action-card">
                                <CardBody>
                                    <CardTitle style={{
                                        fontWeight: 'bold'
                                    }}>Need direction?</CardTitle>
                                    <CardText>Lost? Find your next Hour of Happy</CardText>
                                    <Button className="btn-warning mb-3 shadow card-btn" onClick={handleMap}>The
                                        Map</Button>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="head-text">
                    <div className="head-image">
                        <img className="col-12 rip" src="assets/images/1111.png"
                             style={{display: 'flex', alignItems: 'center'}}></img>
                    </div>
                    <div className="text-on-image">
                        <h1 style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}><strong>Happening
                            Now....</strong></h1>
                    </div>
                </div>
            </div>

            <div className="">
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {data.map((data, index) => {
                        const startHours = data.happyHour.substring(0, 2);
                        const endHours = data.happyHour.substring(6, 8);
                        const IntEndHours = parseInt(endHours);
                        const IntTime = parseInt(time);
                        const happyDays = data.days;
                        if (IntTime === IntEndHours - 1) {
                            if ((time >= startHours && time < endHours) && (happyDays.includes(dayOfWeek))) {
                                return (
                                    <>
                                        <div className="d-block d-md-none">
                                            <div key={data.id}>
                                                <Card
                                                    className="text-center endingSoon card-border bg-gradient shadow p-3 mb-5 rounded uniform-Card-sml"
                                                    onClick={() => handleAddClick(data)}>
                                                    <CardImg src={data.src}
                                                             className="picture-border-sml"/>
                                                    <CardBody>
                                                        <p className="timeNotice"><strong>Ends soon!</strong></p>
                                                        <CardTitle style={{
                                                            fontWeight: 'bold', fontSize: "1.5em"
                                                        }} id="name" className="timeNotice1">{data.bar}:</CardTitle>

                                                        <CardText id="days">{data.offer}</CardText>
                                                        <CardText>{data.happyHour}</CardText>
                                                        <Button className="dark-btn card-btn">Details</Button>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className="d-none d-md-block">
                                            <div key={data.id}>
                                                <Card
                                                    className="text-center endingSoon card-border bg-gradient shadow p-3 mb-5 rounded col-10 uniform-Card"
                                                    onClick={() => handleAddClick(data)}>
                                                    <CardImg src={data.src}
                                                             className="picture-border"/>
                                                    <CardBody>
                                                        <p className="timeNotice"><strong>Ends soon!</strong></p>
                                                        <CardTitle style={{
                                                            fontWeight: 'bold', fontSize: "1.5em"
                                                        }} id="name" className="timeNotice1">{data.bar}:</CardTitle>
                                                        <CardText id="days">{data.address}</CardText>
                                                        <CardText id="days">{data.offer}</CardText>
                                                        <CardText>{data.happyHour}</CardText>
                                                        <Button color="secondary card-btn">Details</Button>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </>

                                )
                            } else {
                                <div></div>
                            }

                        }
                        if ((time >= startHours && time < endHours) && (happyDays.includes(dayOfWeek))) {
                            return (
                                <>
                                    <div className="d-block d-md-none">
                                        <div key={data.id}>
                                            <Card
                                                className="text-center shadow p-3 m-1 mb-3 bg-gradient bg-dark rounded uniform-Card-sml"
                                                onClick={() => handleAddClick(data)}>
                                                <CardImg src={data.src}
                                                         className="picture-border-sml" value={data.bar}/>
                                                <CardBody>
                                                    <CardTitle className="yel-font" style={{
                                                        fontWeight: 'bold', fontSize: "1.5em"
                                                    }} id="name">{data.bar}:</CardTitle>
                                                    <CardText className="wht-font" id="days">{data.offer}</CardText>
                                                    <CardText className="wht-font">{data.happyHour}</CardText>
                                                    <Button className="btn-warning card-btn">Details</Button>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                    {/*{lg below}*/}
                                    <div className="d-none d-md-block">
                                        <div key={data.id}>
                                            <Card
                                                className="text-center shadow p-3 mb-5 m-2 bg-gradient bg-dark rounded col-10 uniform-Card"
                                                onClick={() => handleAddClick(data)} style={{width: ""}}>
                                                <CardImg src={data.src}
                                                         className="picture-border" value={data.bar}/>
                                                <CardBody>
                                                    <CardTitle className="yel-font" style={{
                                                        fontWeight: 'bold', fontSize: "1.5em"
                                                    }} id="name">{data.bar}:</CardTitle>
                                                    <CardText className="wht-font" id="days">{data.address}</CardText>
                                                    <CardText className="wht-font" id="days">{data.offer}</CardText>
                                                    <CardText className="wht-font">{data.happyHour}</CardText>
                                                    <Button className="btn-warning card-btn">Details</Button>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                </>

                            )
                        } else {
                            <div></div>
                        }
                    })}

                    <Row className="container text-center mb-3">
                        <div>
                            <h6><span>That's all for right now. Check out below for what's coming later, or head to our
                                planner to see what's happening the next time you're in town<br/>
                            </span></h6>
                        </div>

                        <div><Button className="btn-warning shadow" onClick={handlePlan}>Plan
                            Ahead</Button>
                        </div>
                    </Row>
                    <Modal isOpen={isModalOpen} toggle={handleClose}>
                        <ModalHeader>{modalData.bar}</ModalHeader>
                        <ModalBody>
                            <h1>{modalData.bar}</h1>
                            <p>{modalData.address}</p>
                            <p>{modalData.happyHour}</p>
                            <p>{modalData.details}</p>
                            <p>(Add up to 6 bars to your planner)</p>
                            <p>{counter}/6 added</p>
                            <Button className="btn-warning m-1" onClick={handleClose}>Cancel</Button>
                            <Button className="btn-success m-1"
                                    onClick={() => dispatch(addToPlanner(modalData.id))}>Add
                                to
                                Planner</Button>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <div className="head-text">
                <div className="head-image">
                    <img className="col-12 rip" src="assets/images/1111.png"
                         style={{display: 'flex', alignItems: 'center'}}></img>
                </div>
                <div className="text-on-image">
                    <h1 style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}><strong>Coming Up
                        Later...</strong></h1>
                </div>
            </div>
            <div>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {data.map((data, index) => {
                        const startHours = data.happyHour.substring(0, 2);
                        const endHours = data.happyHour.substring(6, 8);
                        const IntStartHours = parseInt(startHours);
                        const IntTime = parseInt(time);
                        const happyDays = data.days;
                        if (IntTime === IntStartHours - 1) {
                            if ((time < startHours) && (happyDays.includes(dayOfWeek))) {
                                return (
                                    <>
                                        <div className="d-block d-md-none align-items-center">
                                            <div key={data.id}>
                                                <Card
                                                    className="text-center endingSoon card-border bg-gradient shadow p-3 mb-5 rounded uniform-Card-sml"
                                                    onClick={() => handleAddClick(data)}>
                                                    <CardImg src={data.src}
                                                             className="picture-border-sml"/>
                                                    <p className="timeNotice"><strong>Starts soon!</strong></p>
                                                    <CardBody>
                                                        <CardTitle style={{
                                                            fontWeight: 'bold', fontSize: "1.5em"
                                                        }} id="name" className="timeNotice1">{data.bar}:</CardTitle>
                                                        <CardText id="days">{data.offer}</CardText>
                                                        <CardText>{data.happyHour}</CardText>
                                                        <Button className="dark-btn card-btn">Details</Button>
                                                    </CardBody>

                                                </Card>

                                            </div>
                                        </div>
                                        <div className="d-none d-md-block align-items-center">
                                            <div key={data.id}>
                                                <Card
                                                    className="text-center endingSoon card-border bg-gradient shadow p-3 mb-5 rounded col-10 uniform-Card"
                                                    onClick={() => handleAddClick(data)}>
                                                    <CardImg src={data.src}
                                                             className="picture-border"/>
                                                    <p className="timeNotice"><strong>Starts soon!</strong></p>
                                                    <CardBody>
                                                        <CardTitle style={{
                                                            fontWeight: 'bold', fontSize: "1.5em"
                                                        }} id="name" className="timeNotice1">{data.bar}:</CardTitle>
                                                        <CardText id="days">{data.address}</CardText>
                                                        <CardText id="days">{data.offer}</CardText>
                                                        <CardText>{data.happyHour}</CardText>
                                                        <Button className="dark-btn card-btn">Details</Button>
                                                    </CardBody>
                                                </Card>

                                            </div>
                                        </div>
                                    </>

                                )
                            } else {
                                <div></div>
                            }

                        }
                        if ((time < startHours) && (happyDays.includes(dayOfWeek))) {
                            return (
                                <>
                                    <div className="d-block d-md-none">
                                        <div key={data.id}>
                                            <Card
                                                className="text-center shadow p-3 mb-5 bg-gradient bg-dark rounded uniform-Card-sml"
                                                onClick={() => handleAddClick(data)}>
                                                <CardImg src={data.src}
                                                         className="picture-border-sml"/>
                                                <CardBody className="yel-font">
                                                    <CardTitle style={{
                                                        fontWeight: 'bold', fontSize: "1.5em"
                                                    }} id="name">{data.bar}:</CardTitle>
                                                    <CardText className="wht-font" id="days">{data.offer}</CardText>
                                                    <CardText className="wht-font">{data.happyHour}</CardText>
                                                    <Button className="btn-warning card-btn">Details</Button>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="d-none d-md-block">
                                        <div key={data.id}>
                                            <Card
                                                className="text-center shadow p-3 mb-5 m-2 bg-gradient bg-dark rounded col-10 uniform-Card"
                                                onClick={() => handleAddClick(data)}>
                                                <CardImg src={data.src}
                                                         className="picture-border"/>
                                                <CardBody className="yel-font">
                                                    <CardTitle style={{
                                                        fontWeight: 'bold', fontSize: "1.5em"
                                                    }} id="name">{data.bar}:</CardTitle>
                                                    <CardText className="wht-font" id="days">{data.address}</CardText>
                                                    <CardText className="wht-font" id="days">{data.offer}</CardText>
                                                    <CardText className="wht-font">{data.happyHour}</CardText>
                                                    <Button className="btn-warning card-btn">Details</Button>
                                                </CardBody>
                                            </Card>
                                        </div>

                                    </div>

                                </>
                            )
                        } else {
                            return (
                                <div>
                                    <h2></h2>
                                </div>
                            );
                        }


                    })}
                    <Row className="container text-center mb-3">
                        <div>
                            <h6><span>That's all for today, folks. Come back to tomorrow for more deals, or take a look at the
                                planner to see what's coming up..<br/>
                            </span></h6>
                        </div>

                        <div><Button className="btn-warning shadow" onClick={handlePlan}>Plan
                            Ahead</Button>
                        </div>
                    </Row>


                    <Modal isOpen={isModalOpen} toggle={handleClose}>
                        <ModalHeader>{modalData.bar}</ModalHeader>
                        <ModalBody>
                            <h1>{modalData.bar}</h1>
                            <h3>{modalData.offer}</h3>
                            <p>{modalData.happyHour}</p>
                            <p>{modalData.address}, {modalData.postCode}</p>
                            <p>{modalData.details}</p>
                            <p><strong>Add up to 6 bars to your planner</strong></p>
                            <p>{counter}/6 added</p>
                            <Button className="btn-warning m-1 shadow" onClick={handleClose}>Close</Button>
                            <Button className="btn-success m-1 shadow" onClick={() => handleAdd(modalData)}>Add to
                                Planner</Button>
                        </ModalBody>
                    </Modal>
                    <ToastContainer
                        position="top-right"
                        autoClose={1500}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        draggable
                        pauseOnHover={false}
                        type="warning"
                        className="pt-4"/>
                </div>
            </div>
        </>

    )
};
export default Home;
