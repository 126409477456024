import {createStore, combineReducers} from "redux";
import plannerReducer from "./plannerReducer";
import countReducer from "./countReducer";
import featureReducer from "./featureReducer";
import dayReducer from "./dayReducer";
import timeReducer from "./timeReducer";

const reducer = combineReducers({
    planner: plannerReducer,
    countReducer,
    featureReducer,
    dayReducer,
    timeReducer
});

export default createStore(
    (state, action) => reducer(state, action)

);