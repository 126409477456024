import React, {Component, useEffect, useState} from 'react';
import {GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow} from "react-google-maps";
import {data} from "../data/TableData";
import {Container} from 'reactstrap';
import {Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {addFeature, addDay, addTime} from "../store/actions";

function GoogleMapComp() {
    const [selectedBar, setSelectedBar] = useState(null);
    const show = useSelector((state) => state.featureReducer);
    const day = useSelector((state) => state.dayReducer);
    const time = useSelector((state) => state.timeReducer);

    const chosenFeature = show.state.feature;
    const chosenDay = day.state.day;
    const chosenTime = time.time;


    return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{lat: 53.480759, lng: -2.242631}}
        >
            {data.map((bars, index) => {
                const startHours = bars.happyHour.substring(0, 2);
                const endHours = bars.happyHour.substring(6, 8);
                if ((chosenTime === null) && (bars.days.includes(chosenDay))) {
                    return (
                        <Marker position={{lat: bars.lat, lng: bars.long}} onClick={() => {
                            setSelectedBar(bars)
                        }} style={{value: bars.bar}} animation={window.google.maps.Animation.DROP}>

                        </Marker>
                    );
                } else if ((chosenDay === null) && (chosenTime >= startHours && chosenTime < endHours)) {
                    return (
                        <Marker position={{lat: bars.lat, lng: bars.long}} onClick={() => {
                            setSelectedBar(bars)
                        }} style={{label: bars.bar}} animation={window.google.maps.Animation.DROP}>
                        </Marker>
                    );
                } else if ((bars.days.includes(chosenDay)) && (chosenTime >= startHours && chosenTime < endHours)) {
                    return (
                        <Marker position={{lat: bars.lat, lng: bars.long}} onClick={() => {
                            setSelectedBar(bars)
                        }} style={{value: bars.bar}} animation={window.google.maps.Animation.DROP}>
                        </Marker>

                    );
                } else {
                    return (
                        <Marker/>
                    );
                }
            })}
            {selectedBar && (
                <InfoWindow
                    position={{
                        lat: selectedBar.lat,
                        lng: selectedBar.long
                    }}
                    onCloseClick={() => {
                        setSelectedBar(null);
                    }}
                >
                    <div>
                        <h2 style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>{selectedBar.bar}</h2>
                        <p style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>{selectedBar.offer}</p>
                        <p style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>{selectedBar.days}</p>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
}

function BarOptions({options}) {
    return (
        options.map(option =>
            <option key={option.id} value={option.bar}>
                {option.bar}
            </option>)
    );
}

function OfferOptions({options}) {
    return (
        options.map(option =>
            <option key={option.id} value={option.offer}>
                {option.offer}
            </option>)
    );
}


const WrappedMap = withScriptjs(withGoogleMap(GoogleMapComp));

const Map = () => {
    const dispatch = useDispatch();
    const [show, updateShow] = useState(null);
    const [dayChosen, updateDayChosen] = useState(null);
    const [timeChosen, updateTimeChosen] = useState(null);
    const [offerChosen, updateOfferChosen] = useState(null);
    const [barChosen, updateBarChosen] = useState(null);

    const addToFeature = (e) => {
        updateShow(e);
    };

    const addToDay = (e) => {
        updateDayChosen(e);
    };

    const addToTime = (e) => {
        updateTimeChosen(e);
    };

    const addToOffer = (e) => {
        updateOfferChosen(e);
    };
    const addToBar = (e) => {
        updateBarChosen(e);
    };


    useEffect(() => {
        dispatch(addFeature(show));
    },);

    useEffect(() => {
        dispatch(addDay(dayChosen));
    },);

    useEffect(() => {
        dispatch(addTime(timeChosen));
    },);

    return (
        <>
            <div className="d-none d-lg-block">
                <Container className="mt-3">
                    <div className="row">
                        <Col>
                            <div className="text-center">

                                <h1><span><strong>What's happening the next time you're in town?</strong><br/></span></h1><br/>
                            </div>
                            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>

                                <h6><span>Find your Manchester Happy Hour's</span></h6>

                            </div>
                            <br/>
                            <div style={{height: "80vh"}} className="mb-3">
                                <WrappedMap
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQgDUJ8pI2CTfxIaQGAzu6R9DBmuGp8JE&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{height: "100%"}}/>}
                                    containerElement={<div style={{height: "100%"}}/>}
                                    mapElement={<div style={{height: "100%"}}/>}
                                />
                            </div>
                        </Col>
                        <Col xs={2} className="pt-5 lg-align">

                            {/*                <h5>Show:</h5>
                <select
                    name="feature"
                    className="form-control"
                    id="feature" onClick={e => addToFeature(e.currentTarget.value)}>
                    <option disabled selected>Please Select...</option>
                    <option value ="All">All</option>
                    <option value="Recommended">Today's Recommended</option>
                </select><br/>*/}

                            <h5>Select by Day:</h5>
                            <select
                                name="day"
                                className="form-control" onClick={e => addToDay(e.currentTarget.value)}>
                                <option disabled selected>Please Select...</option>
                                <option value="Mon">Monday</option>
                                <option value="Tue">Tuesday</option>
                                <option value="Wed">Wednesday</option>
                                <option value="Thu">Thursday</option>
                                <option value="Fri">Friday</option>
                                <option value="Sat">Saturday</option>
                                <option value="Sun">Sunday</option>
                            </select><br/>

                            <h5>Select by Time:</h5>
                            <select
                                name="bar"
                                className="form-control" onClick={e => addToTime(e.currentTarget.value)}>
                                <option disabled selected>Please Select...</option>
                                <option value="11">11:00am</option>
                                <option value="12">12:00pm</option>
                                <option value="13">1:00pm</option>
                                <option value="14">2:00pm</option>
                                <option value="15">3:00pm</option>
                                <option value="16">4:00pm</option>
                                <option value="17">5:00pm</option>
                                <option value="18">6:00pm</option>
                                <option value="19">7:00pm</option>
                                <option value="20">8:00pm</option>
                                <option value="21">9:00pm</option>
                                <option value="22">10:00pm</option>
                            </select><br/>

                            {/*                <h5>Select by Offer:</h5>
                <select
                    name="offer"
                    className="form-control" onClick={e => addToOffer(e.currentTarget.value)}>
                    <option value ="0" disabled selected>Please Select...</option>
                    <OfferOptions options={data}/>
                </select><br/>

                <h5>Select by Bar:</h5>
                <select
                    name="bar"
                    className="form-control" onClick={e => addToBar(e.currentTarget.value)}>
                    <option value ="0" disabled selected>Please Select...</option>
                    <BarOptions options={data}/>
                </select>*/}<br/>

                            <p className="text-center">(Please note, offers may vary on public holidays or during special events)</p>


                        </Col>
                    </div>
                </Container>
            </div>

            <div className="d-block d-lg-none text-center">
                <Container className="mt-3">
                    <div className="row">
                        <div className="text-center">
                            <h1><strong>Find out what's on the next time you're in town</strong><br/></h1><br/>
                        </div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>

                            <p>Find your Manchester Happy Hour's</p>

                        </div>
                        <br/>
<div className="container">
                        <h5>Select by Day:</h5>
                        <select
                            name="day"
                            className="form-control" onClick={e => addToDay(e.currentTarget.value)}>
                            <option disabled selected>Please Select...</option>
                            <option value="Mon">Monday</option>
                            <option value="Tue">Tuesday</option>
                            <option value="Wed">Wednesday</option>
                            <option value="Thu">Thursday</option>
                            <option value="Fri">Friday</option>
                            <option value="Sat">Saturday</option>
                            <option value="Sun">Sunday</option>
                        </select>

                        <h5 className="mt-2">Select by Time:</h5>
                        <select
                            name="bar"
                            className="form-control" onClick={e => addToTime(e.currentTarget.value)}>
                            <option disabled selected>Please Select...</option>
                            <option value="11">11:00am</option>
                            <option value="12">12:00pm</option>
                            <option value="13">1:00pm</option>
                            <option value="14">2:00pm</option>
                            <option value="15">3:00pm</option>
                            <option value="16">4:00pm</option>
                            <option value="17">5:00pm</option>
                            <option value="18">6:00pm</option>
                            <option value="19">7:00pm</option>
                            <option value="20">8:00pm</option>
                            <option value="21">9:00pm</option>
                            <option value="22">10:00pm</option>
                        </select><br/>
</div>

                        {/*                <h5>Select by Offer:</h5>
                <select
                    name="offer"
                    className="form-control" onClick={e => addToOffer(e.currentTarget.value)}>
                    <option value ="0" disabled selected>Please Select...</option>
                    <OfferOptions options={data}/>
                </select><br/>

                <h5>Select by Bar:</h5>
                <select
                    name="bar"
                    className="form-control" onClick={e => addToBar(e.currentTarget.value)}>
                    <option value ="0" disabled selected>Please Select...</option>
                    <BarOptions options={data}/>
                </select>*/}<br/>

                        <p className="mt-2 text-center">(Please note, offers may vary on public holidays or during special events)</p><br/>
                        <div style={{height: "80vh"}} className="mb-3">
                            <WrappedMap
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQgDUJ8pI2CTfxIaQGAzu6R9DBmuGp8JE&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{height: "100%"}}/>}
                                containerElement={<div style={{height: "100%"}}/>}
                                mapElement={<div style={{height: "100%"}}/>}
                            />
                        </div>
                        {/*                <h5>Show:</h5>
                <select
                    name="feature"
                    className="form-control"
                    id="feature" onClick={e => addToFeature(e.currentTarget.value)}>
                    <option disabled selected>Please Select...</option>
                    <option value ="All">All</option>
                    <option value="Recommended">Today's Recommended</option>
                </select><br/>*/}
                    </div>
                </Container>
            </div>

        </>
    );
}

export default Map